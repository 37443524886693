<template>
  <div class="hello">
    <section class="banner">
      <map-view style="height: 600px" :center="center" :markers="markers" :zoom="8" class="w-full map">
        <template v-slot:control>
          <l-control position="topleft">
            <!-- quick search bar -->
            <div class="filters w-full my-auto">
              <div class="container mx-auto mt-5" style="z-index: 400">
                <form class="w-full">
                  <div class="advance-search-bar grid grid-cols-12 pt-12">
                    <div class="col-span-4">
                      <div class="text-fields-filters mt-40 px-12 pt-6 relative" :class="[expanded ? 'rounded-t-2xl' : 'rounded-2xl']" style="background-color: #c4c4c4">
                        <AutoComplete
                          class="w-full p-fluid mb-3"
                          v-model="category"
                          field="name"
                          :suggestions="filtered.categories"
                          placeholder="Skill"
                          @complete="searchCategory($event)">
                          <template #item="slotProps">
                            <div class="flex align-options-center">
                              <div class="grid w-full">
                                <div class="col-10 pb-0">{{ slotProps.item.name }}</div>
                                <div class="col-4 pt-0 text-xs">{{ slotProps.item.breadcrumb }}</div>
                              </div>
                            </div>
                          </template>
                        </AutoComplete>

                        <AutoComplete
                          class="w-full p-fluid"
                          :class="[expanded ? 'mb-3' : '']"
                          v-model="location"
                          field="name"
                          :suggestions="filtered.countries"
                          placeholder="Country"
                          @complete="searchCountry($event)" />

                        <AutoComplete
                          v-if="expanded"
                          :class="[expanded ? 'mb-3' : '']"
                          class="w-full p-fluid"
                          v-model="region"
                          :suggestions="filtered.regions"
                          placeholder="Region"
                          @complete="searchRegion($event)" />

                        <InputText v-if="expanded" type="text" v-model="postCode" class="w-full" placeholder="Post Code" />

                        <Button icon="pi pi-search" label="Search Now" style="margin-top: 1rem; margin-bottom: 1rem" @click.prevent="search" />

                        <button class="bg-white px-4 py-3 absolute bottom-0 right-8 rounded-t-2xl" @click.prevent="expanded = !expanded">
                          <font-awesome-icon class="fa-lg" :icon="['fas', 'bars-progress']" />
                        </button>
                      </div>

                      <div v-if="expanded" class="button-filters px-12 pt-6 rounded-b-2xl" style="background: white">
                        <button class="bg-blue-500 hover:bg-blue-700 text-white rounded-full font-normal py-1 px-4 mr-4 mb-4">opt. 01</button>
                        <button class="bg-blue-500 hover:bg-blue-700 text-white rounded-full font-normal py-1 px-4 mr-4 mb-4" style="background-color: #e5e5e5; color: #000">
                          opt. 02
                        </button>
                        <button class="bg-blue-500 hover:bg-blue-700 text-white rounded-full font-normal py-1 px-4 mr-4 mb-4" style="background-color: #000">opt. 01</button>

                        <button class="bg-blue-500 hover:bg-blue-700 text-white rounded-full font-normal py-1 px-4 mr-4 mb-4">opt. 01</button>
                        <button class="bg-blue-500 hover:bg-blue-700 text-white rounded-full font-normal py-1 px-4 mr-4 mb-4" style="background-color: #e5e5e5; color: #000">
                          opt. 02
                        </button>
                        <button class="bg-blue-500 hover:bg-blue-700 text-white rounded-full font-normal py-1 px-4 mr-4 mb-4" style="background-color: #000">opt. 01</button>

                        <button class="bg-blue-500 hover:bg-blue-700 text-white rounded-full font-normal py-1 px-4 mr-4 mb-4">opt. 01</button>
                        <button class="bg-blue-500 hover:bg-blue-700 text-white rounded-full font-normal py-1 px-4 mr-4 mb-4" style="background-color: #e5e5e5; color: #000">
                          opt. 02
                        </button>
                        <button class="bg-blue-500 hover:bg-blue-700 text-white rounded-full font-normal py-1 px-4 mr-4 mb-4" style="background-color: #000">opt. 01</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <!-- end of quick search bar -->
          </l-control>
        </template>
      </map-view>
    </section>

    <section class="top-consultants" v-if="consultantsVisible">
      <div class="container mx-auto m-12">
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 user-grid">
          <consultant v-for="(consultant, index) in searchResult" :key="index" v-bind="consultant" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { LControl } from 'vue2-leaflet';
import LocationReceiver from '@/plugins/location-receiver.js';
import MapView from '@/components/MapView.vue';
import Consultant from '@/components/Consultant.vue';
import countries from '@/plugins/country-regions';
import AutoComplete from 'primevue/autocomplete';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import consultant_service from '@/services/consultant_service';
import category_service from '@/services/category_service';

export default {
  name: 'Search',
  components: {
    'map-view': MapView,
    consultant: Consultant,
    LControl,
    AutoComplete,
    InputText,
    Button,
  },
  data: () => {
    return {
      center: [0, 0],
      zoom: 10,
      consultants: [],
      categories: [],
      countries: countries,
      location: null,
      filtered: {
        categories: [],
        regions: [],
        countries: [],
      },
      region: '',
      postCode: '',
      category: '',
      expanded: false,
    };
  },
  computed: {
    consultantsVisible() {
      return this.consultants.length > 0;
    },
    markers() {
      return this.consultants.map((consultant) => {
        return {
          latLng: [consultant.latitude, consultant.longitude],
          popup: consultant.name,
        };
      });
    },
    searchResult() {
      return this.consultants.map((consultant) => {
        return {
          id: consultant.id,
          name: consultant.name,
          tagline: consultant.tagline,
          description: consultant.description,
          rating: 5,
        };
      });
    },
    regions() {
      if (this.location == null) {
        return [];
      } else {
        return this.location.regions;
      }
    },
  },
  methods: {
    setLocation() {
      this.setFiltersFromGPS();
    },
    onLocationChanged() {
      if (this.location != null) {
        this.focusCountry(4);
      } else {
        this.center = [0, 0];
        this.zoom = 4;
      }
      this.region = '';
    },
    setFiltersFromGPS() {
      LocationReceiver.getCountryName(this.center[0], this.center[1]).then((currentLocation) => {
        this.countries.filter((country) => {
          if (country.name === currentLocation) {
            this.location = country;
            this.focusCountry(4);
          }
        });
      });
    },
    focusCountry(zoom) {
      LocationReceiver.getCountryCoordinates(this.location.name).then(({ latitude, longitude }) => {
        this.center = [latitude, longitude];
        this.zoom = zoom;
      });
    },
    searchCategory(event) {
      if (!event.query.trim().length) {
        this.filtered.categories = [...this.categories];
      } else {
        this.filtered.categories = this.categories.filter((cat) => {
          return cat.name.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }
    },
    searchCountry(event) {
      if (!event.query.trim().length) {
        this.filtered.countries = [...this.countries];
      } else {
        this.filtered.countries = this.countries.filter((c) => {
          return c.name.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }
    },
    searchRegion(event) {
      if (!event.query.trim().length) {
        this.filtered.regions = [...this.regions];
      } else {
        this.filtered.regions = this.regions.filter((r) => {
          return r.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }
    },
    search() {
      let terms = [];
      if (this.location != null) {
        terms.push(this.location.name);
      }
      if (this.expanded) {
        terms.push(this.region);
        terms.push(this.postCode);
      }
      terms.push(this.category);
      consultant_service.search(terms).then((res) => {
        this.consultants = [];
        if (res.data.status && Array.isArray(res.data.consultants)) {
          this.consultants = res.data.consultants;
          this.zoom = 8;
        }
      });
    },
  },
  mounted() {
    this.setLocation();
    category_service
      .getCategories()
      .then((response) => {
        this.categories = response.data.categories;
      })
      .then(() => {
        this.category = this.$route.params.category || '';
        if (this.category) {
          this.search();
        }
      });
    LocationReceiver.getLocation()
      .then(({ latitude, longitude }) => {
        this.center = [latitude, longitude];
      })
      .then(() => {
        this.setLocation();
      });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
// @import "~@/scss/variables.scss";
</style>
