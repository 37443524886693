import axios from 'axios';

let getLocation = function () {
  return new Promise((success, failure) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        success({ latitude: position.coords.latitude, longitude: position.coords.longitude });
      });
    } else {
      failure('Geolocation is not supported by this browser.');
    }
  });
};

let getCountryName = function (lat, lng) {
  return new Promise((success, failure) => {
    axios
      .get('https://nominatim.openstreetmap.org/reverse', {
        params: { format: 'json', lat: lat, lon: lng },
      })
      .then(({ data }) => {
        success(data.address.country);
      })
      .catch((err) => failure(err));
  });
};

let getCountryCoordinates = function (country) {
  return new Promise((success, failure) => {
    axios
      .get('https://nominatim.openstreetmap.org/search', {
        params: {
          country: country,
          format: 'json',
        },
      })
      .then(({ data }) => {
        if (data.length > 0) {
          success({ latitude: data[0].lat, longitude: data[0].lon });
        }
      })
      .catch((err) => failure(err));
  });
};

export default {
  getLocation,
  getCountryName,
  getCountryCoordinates,
};
