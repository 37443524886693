<template>
  <router-link
    :to="{ name: 'consultant_public', params: { id: this.id } }"
    class="sahdow-lg item flex w-full flex-col items-start justify-start overflow-hidden bg-white drop-shadow-md">
    <div class="w-full">
      <img class="h-auto w-full object-cover object-center" :src="this.imageUrl" alt="photo" />
    </div>

    <div class="flex px-3 stars">
      <rating-bar :rating="this.rating" />
    </div>

    <div class="h-full w-full py-8 px-3 sm:py-6">
      <router-link class="name mb-2 text-xl font-normal" :to="{ name: 'consultant_public', params: { id: this.id } }">{{ this.name }}</router-link>
      <p class="job-title text-base font-normal">{{ this.tagline }}</p>
      <p class="desc pt-2 description">{{ this.description }}</p>
    </div>
  </router-link>
</template>

<script>
import RatingBar from './RatingBar.vue';

export default {
  name: 'Consultant',
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    tagline: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    rating: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      imageUrl: '',
    };
  },
  components: {
    'rating-bar': RatingBar,
  },
  mounted() {
    this.$s3.getDpUrl(this.id).then((url) => {
      this.imageUrl = url;
    });
  },
};
</script>

<style scoped>
.description {
  font-size: 12px;
}
</style>
